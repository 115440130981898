<template>
	<v-container v-if="$can('view', 'Service')" fluid>
		<div v-if="$can('create', 'Service')" class="text-right">
			<v-btn rounded color="primary" dark @click="createService()">
				+ Create Service
			</v-btn>
		</div>

		<v-row v-if="services.length > 0" class="mt-4">
			<v-card class="d-flex flex-column elevation-0 pa-0">
				<v-data-table
					flat
					:headers="headers"
					:items="services"
					:search="search"
					:hide-default-footer="true"
					class="pt-2"
					disable-pagination
				>
					<template v-slot:[`item.name`]="{ item }">
						<h3
							class="name"
							color="#5F615A"
							v-if="item.isPublished"
						>
							<a
								:href="`${url}/services/${item.slug}`"
								target="_blank"
							>
								{{ item.name }}
							</a>
						</h3>
						<h3 class="name" color="#5F615A" v-else>
							{{ item.name }}
						</h3>
					</template>

					<template v-slot:[`item.description`]="{ item }">
						<div
							class="description ma-0"
							label
							v-html="item.description"
						></div>
					</template>

					<template v-slot:[`item.isPublished`]="{ item }">
						<StatusChips
							:is-published="item.isPublished"
						></StatusChips>
					</template>

					<template v-slot:[`item.actions`]="{ item }">
						<div class="d-flex flex-row justify-center py-8">
							<!-- Edit Button -->
							<v-tooltip v-if="$can('update', 'Service')" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										small
										fab
										color="blue"
										class="mr-2"
										@click="editService(item.id)"
									>
										mdi-pencil
									</v-icon>
								</template>
								<span class="tooltip-text">Edit</span>
							</v-tooltip>

							<!-- Delete Button -->
							<v-tooltip v-if="$can('delete', 'Service')" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										small
										fab
										color="red"
										class="mr-2"
										@click="
											;(deleteServiceId = item.id),
												(showDeleteAlert = true)
										"
									>
										mdi-delete
									</v-icon>

									<v-dialog
										v-model="showDeleteAlert"
										max-width="600px"
										v-if="showDeleteAlert"
									>
										<v-card
											class="
												pa-6
												mb-0
												d-flex
												flex-column
												delete-modal
											"
										>
											<div
												class="
													d-flex
													flex-row
													align-center
												"
											>
												<span
													class="
														mdi mdi-alert-circle
														icon
														mr-2
													"
												></span>
												<h1 class="heading">
													Are you sure you want to
													delete?
												</h1>
											</div>
											<div
												class="
													d-flex
													flex-row
													justify-end
												"
											>
												<v-btn
													class="mt-4 mr-4"
													outlined
													color="#721c24"
													@click="
														;(deleteConfirmed = true),
															deleteService()
													"
												>
													Delete
												</v-btn>
												<v-btn
													class="mt-4"
													outlined
													color="indigo"
													@click="
														;(showDeleteAlert = false),
															(deleteConfirmed = false)
													"
												>
													Cancel
												</v-btn>
											</div>
										</v-card>
									</v-dialog>
								</template>
								<span class="tooltip-text">Delete</span>
							</v-tooltip>

							<!-- Publish/Unpublish Toggle Button -->
							<v-tooltip v-if="$can('publish', 'Service')" bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										small
										fab
										color="orange"
										outlined
										v-if="item.isPublished"
										@click="toggleStatus(item)"
									>
										mdi-arrow-collapse-down
									</v-icon>
									<v-icon
										v-bind="attrs"
										v-on="on"
										small
										fab
										color="success"
										outlined
										v-else
										class="unpublish-btn"
										@click="toggleStatus(item)"
									>
										mdi-arrow-collapse-up
									</v-icon>
								</template>
								<span
									class="tooltip-text"
									v-if="item.isPublished"
								>
									Unpublish
								</span>
								<span class="tooltip-text" v-else>
									Publish
								</span>
							</v-tooltip>

							<v-tooltip
								v-if="
									!$can('update', 'Service') &&
									!$can('delete', 'Service') &&
									!$can('publish', 'Service')
								"
								bottom
							>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										small
										fab
										color="orange"
									>
										mdi-alert
									</v-icon>
								</template>
								<span class="tooltip-text">
									Not Authorized
								</span>
							</v-tooltip>
						</div>
					</template>
				</v-data-table>
			</v-card>

			<Paginator
				:paginator="paginator"
				:url="getUrl()"
				@update="changePage($event)"
			></Paginator>
		</v-row>

		<NoData
			v-else
			createButtonText="+ Create Service"
			@create-button="createService()"
		></NoData>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import NoData from '../../components/NoData'
import StatusChips from '../../components/StatusChips'

export default {
	components: {
		NoData,
		StatusChips,
		Paginator,
	},
	data() {
		return {
			headers: [
				{
					text: 'NAME',
					value: 'name',
					align: 'start',
					width: '15%',
					sortable: true,
				},
				{
					text: 'DESCRIPTION',
					value: 'description',
					align: 'start',
					sortable: false,
				},
				{
					text: 'STATUS',
					value: 'isPublished',
					align: 'center',
					sortable: true,
				},
				{
					text: 'ACTIONS',
					value: 'actions',
					align: 'center',
					width: '15%',
					sortable: false,
				},
			],
			initData: {},
			paginator: {},
			search: '',
			showDeleteAlert: false,
			services: [],
			deleteConfirmed: false,
			deleteServiceId: null,
			isLoading: false,
		}
	},
	created() {
		this.isLoading = true
		this.getListingData()
	},
	methods: {
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/services`
			return url
		},
		changePage(data) {
			this.isLoading = true
			this.services = data.data
			this.paginator = data.paginator
			this.isLoading = false
		},
		getListingData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/services`,
			}).then((response) => {
				this.paginator = response.data.paginator
				let _data = response.data.data
				this.services = _data
			})
			this.isLoading = false
		},
		editService(serviceId) {
			this.$router.push({
				name: 'service.edit',
				params: { id: serviceId },
			})
		},
		deleteService() {
			this.showDeleteAlert = true
			const serviceId = this.deleteServiceId
			console.log(serviceId)
			if (this.deleteConfirmed === true) {
				this.showDeleteAlert = false
				const projectId = this.$store.state.app.projectId
				this.axios({
					method: 'delete',
					url: `/${projectId}/services/${serviceId}`,
				}).then(() => {
					this.getListingData()
					this.deleteConfirmed = false
				})
			}
		},
		toggleStatus(item) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/services/toggle-status/${item.id}`,
			}).then((response) => {
				let _data = response.data
				if (_data.success) {
					item.isPublished = response.data.data.isPublished
					this.getListingData()
				} else if ('errors' in _data) {
					console.log(_data.errors)
				}
			})
		},
		createService() {
			this.$router.push({
				name: 'service.create',
			})
		},
	},
	computed: {
		url() {
			let domain = this.$store.getters['app/getCurrentProjectDomain']
			let serviceURL = `http://${domain}`
			return serviceURL
		},
	},
}
</script>

<style scoped>
.description {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.name {
	font-size: 14px !important;
	line-height: 1.4 !important;
}
.name a {
	color: black !important;
	text-decoration: none !important;
}
.name a:hover {
	color: var(--blue) !important;
}
</style>
